<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список категорий</div>
      <button class="btn btn-success" @click="openModal('createCategory')">Добавить категорию</button>
    </div>
    <table class="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Название</th>
        <th scope="col">Порядок</th>
        <th scope="col">Видимость</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in categoryInfo" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td class="pointer"><router-link :to="'/view-category/' + item.id">{{ item.name }}</router-link></td>
        <td><input class="very-small" type="number" @change="editCategorySort(item.id, $event.target.value)" placeholder="Порядок" :value="item.sort"></td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" @change="changeCategoryVisible(item.id)" :checked="parseInt(item.active)">
          </div>
        </td>
        <td class="actions">
          <router-link :to="'/view-category/' + item.id">
            <i class="fas fa-eye"></i>
          </router-link>
          <router-link :to="'/edit-category/' + item.id">
            <i class="fas fa-pen"></i>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <create-category v-show="this.$store.state.modal.createCategory"/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import CreateCategory from "../../components/modal/category/create-category";

export default {
  name: 'list-category',
  components: {CreateCategory},
  computed: {
    ...mapGetters(['categoryInfo']),
  },
  async mounted() {
    await this.getIndexInfo();
  },
  methods: {
    getIndexInfo: function(){
      this.$store.dispatch('getCategories');
    },
    editCategorySort: function(id, value){
      this.$store.dispatch('editCategorySort', {'id': id, 'sort': value});
    },
    changeCategoryVisible: function(id){
      this.$store.dispatch('changeActiveCategory', id);
    },
    openModal: function(name){
      this.$store.dispatch('openModal', name);
    },
  }
}
</script>